import { useEffect, useState } from "react";
import Rack from "./Rack";
import shelfHttpService from "../services/http/shelf-management/shelf.service";
import { useParams } from "react-router-dom";
import notificationService from "../services/toaster.service";
import UnlimitLoading from "../components/ui-kit/unlimit-loading";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";


const RackList = () => {
  const location = useLocation();
  const { name, image } = location.state || {};
  const [racks, setRacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: outletId } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await shelfHttpService.getSaveProduct(outletId);
        if (response.status === 200) {
          setRacks(
            response.data.data.map((rack) => ({
              ...rack,
              shelves: rack.shelves.map((shelf) => ({
                ...shelf,
                sensors: shelf.sensors.map((sensor) => ({
                  ...sensor,
                  products: sensor.products || [],
                })),
              })),
            }))
          );
        }
      } catch (err) {
        setError("Failed to fetch outlet data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [outletId]);

  const addRack = () => {
    setRacks([
      ...racks,
      { id: null, name: `Rack ${racks.length + 1}`, shelves: [] },
    ]);
  };

  const saveAllRacks = async () => {
    setLoading(true);
    const payload = {
      outlet: {
        id: outletId,
        racks: racks.map((rack) => ({
          id: rack.id,
          name: rack.name,
          shelves: rack.shelves.map((shelf) => ({
            id: shelf.id,
            name: shelf.name,
            sensors: shelf.sensors.map((sensor) => ({
              id: sensor.id,
              sensor_number: sensor.sensor_number,
              products: sensor.products.map((product) => ({
                product_id: product.id,
                quantity: product.quantity || 0,
              })),
            })),
          })),
        })),
      },
    };

    try {
      const response = await shelfHttpService.selectProduct(payload, true);
      if (response.status === 200) {
        notificationService.successMessage("Data saved successfully!");
      } else {
        throw new Error("Failed to save data.");
      }
    } catch (err) {
      setError("Failed to save racks data. Please try again.");
      notificationService.errorMessage("Failed to save racks data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="d-flex justify-content-center"><UnlimitLoading type="spin" color="#82B632" height={100} width={100} /></div>
  if (error) return <p className="text-danger">{error}</p>

  return (
    <div>
          <div>
      {name && image ? (
        <div className="d-flex align-items-center mb-3">
          <img src={image} alt={name} className="me-3" style={{ width: "80px" }} />
          <p className="fs-6 fw-bold">{name}</p>
        </div>
      ) : (
        <p>No data available.</p>
      )}
    </div>
      {racks.map((rack, index) => (
        <Rack
          key={rack.id || index}
          id={rack.id}
          number={index + 1}
          name={rack.name}
          shelves={rack.shelves}
          setRacks={setRacks}
        />
      ))}
      <button onClick={addRack} className="btn btn-primary mt-3">
        {t('shelf.addRack')}
      </button>
      <button onClick={saveAllRacks} className="btn btn-success mt-3 ms-3">
        {t("shelf.saveAll")}
      </button>
    </div>
  );
};

export default RackList;
