import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitAttachments from "../../../components/ui-kit/unlimit-attachments";
import httpService from "../../../services/http/products/product.service";
import notificationService from "../../../services/toaster.service";
import { hydrateErrors } from "../../../utils/error.utils";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

const ProductStep2 = ({
  handlePrevious,
  formData,
  setFormData,
  existingData,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { isVendorOrOutlet } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const { t } = useTranslation();
  const [imageUrlAttachments, setImageUrlAttachments] = useState("");
  const [existingImageUrlAttachments, setExistingImageUrlAttachments] =
    useState("");

  const watchFile = watch("attachments");

  useEffect(() => {
    if (watchFile?.length > 0) {
      setImageUrlAttachments(URL.createObjectURL(watchFile[0]));
    }
  }, [watchFile]);
  useEffect(() => {
    if (existingData) hydratePredefinedValues(existingData);
  }, [existingData]);

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setExistingImageUrlAttachments(data.catalog?.product_images);
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    const newFormData = new FormData();
    for (const [key, value] of formData.entries()) {
      if (value !== null && value !== undefined) {
        newFormData.append(key, value);
      }
    }
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        newFormData.append(`attachments[${i}]`, fileList[i]);
      }
    }
    setFormData(newFormData);
    try {
      setIsLoading(true);
      if (id) {
        newFormData.append("id", id);
        await httpService
          .updateProduct(newFormData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t('product.productUpdateSuccess')
              );
              setTimeout(() => {
                navigate(`/product/${id}/details`)
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        await createProduct(newFormData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const createProduct = async (formData) => {
    try {
      await httpService
        .createProduct(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(t('product.productCreateSuccess'));
            navigate(all_routes.productList);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const handlePreviousClick = (e) => {
    e.preventDefault();
    handlePrevious();
  };
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="card">
        <div className="card-body pb-0">
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            <div>
              <div className="row">
                <div className="col-lg-12">
                <UnlimitAttachments
                      label={t('catalog.productImages')}
                      id="attachments"
                      rules={{
                        required: !id && t('inputField.validation.required'),
                      }}
                      register={register}
                      disabled={isVendorOrOutlet}
                      error={errors.attachments}
                      errorMessage={errors.attachments?.message}
                      imageUrlAttachments={imageUrlAttachments}
                      showLogo
                      existingImageUrlAttachments={existingImageUrlAttachments}
                      setExistingImageUrlAttachments={setExistingImageUrlAttachments}
                      accept=".jpeg, .png, .jpg"
                      fileList={fileList}
                      setFileList={setFileList}
                      setButtonDisabled={setButtonDisabled}
                    />
                </div>
              </div>
              <div className="col-lg-12 mb-4 mt-3">
                <button
                  className="btn btn-cancel me-2"
                  onClick={handlePreviousClick}
                >
                  {t('general.button.back')}
                </button>
                <UnlimitSubmitButton
                  label={ id ? t("general.button.update") : t("general.button.submit")}
                  isLoading={isLoading}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ProductStep2;
