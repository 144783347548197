import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import httpService from "../../../../services/http/stores/outlet.service";
import usePagination, {
  createInitialPagination,
} from "../../../../custom-hooks/usePagination";
import UnlimitTable from "../../../../components/ui-kit/unlimit-table";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DatePicker } from "antd";

const OutletDetailsLogs = ({type,id}) => {
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [selectedVendor, setSelectedVendor] = useState([]);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const initialPagination = createInitialPagination(t);
  const { paginationParams, updatePaginationParams } =
    usePagination(initialPagination);
  const getOutletsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    1;
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .outletLogsList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD HH:MM:SS") : null,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD HH:MM:SS") : null,
    };
    if(type == "CUSTOMER" || type == "EMP"){
      filters.resourceable_id=id
    } else if(type == "OUTLET"){
      filters.outlet_id=id
    } 
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if (!pagination) {
      pagination = initialPagination;
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getOutletsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    handleTableChange();
  }, []);
  console.log(startDate,endDate)
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams.pagination?.current - 1) *
          paginationParams.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.userName"),
      dataIndex: "entry_time",
      render:(text,record)=>record.resourceable?.name
    },
    {
      title: t("tableColumnName.shopeEnterTime"),
      dataIndex: "entry_time",
      // sorter: true,
      render: (text, record) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
    {
      title: t("tableColumnName.shopExitTime"),
      dataIndex: "exit_time",
      // sorter: true,
      render: (text, record) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
    {
      title: t("logs.type"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.resourceable?.type == "employee") {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("employee.employee")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("customer.customer")}</a>
            </span>
          );
        }
      },
    }
  ];

  return (
    <div>
      {/* /product list */}
          <div className="table-top row">
            <div className="col-lg-5 col-sm-4 col-12">
            <DatePicker style={{width:"100%",height:"40px"}}  showTime onChange={(_, dateStr) => setStartDate(dateStr)} placeholder="Start Date"/>
            </div>
            <div className="col-lg-5 col-sm-4 col-12">
            <DatePicker style={{width:"100%",height:"40px"}}  showTime onChange={(_, dateStr) =>setEndDate(dateStr)} placeholder="End Date"/>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 ms-auto">
              <div className="input-blocks mb-0">
                <button
                  className="btn btn-filters col-auto ms-auto px-2"
                  style={{ width: "fit-content" }}
                  onClick={handleTableChange}
                >
                  <i data-feather="search" className="feather-search m-0" />
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <UnlimitTable
              pagination={paginationParams.pagination}
              columns={columns}
              dataSource={designations}
              rowKey={(record) => record.id}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
    </div>
  );
};

export default OutletDetailsLogs;
