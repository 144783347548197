import React, { useState } from "react";
import SensorModal from "./model-Sensor";
import ConfirmationAlertCross from "./confirm-alert-cross";
import { PlusCircle, XCircle } from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../core/img/imagewithbasebath";
import { useTranslation } from "react-i18next";


const Shelf = ({ rackId, name, sensors, setRacks }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSensorIndex, setCurrentSensorIndex] = useState(null);
  const { t } = useTranslation();


  const handleSensorClick = (index) => {
    setCurrentSensorIndex(index);
    setModalIsOpen(true);
  };

  const addSensor = () => {
    setRacks((prev) =>
      prev.map((rack) =>
        rack.id === rackId
          ? {
              ...rack,
              shelves: rack.shelves.map((shelf) =>
                shelf.name === name
                  ? {
                      ...shelf,
                      sensors: [
                        ...shelf.sensors,
                        {
                          sensor_number: shelf.sensors.length + 1,
                          products: [],
                        },
                      ],
                    }
                  : shelf
              ),
            }
          : rack
      )
    );
  };

  const deleteShelf = () => {
    setRacks((prev) =>
      prev.map((rack) =>
        rack.id === rackId
          ? {
              ...rack,
              shelves: rack.shelves.filter((shelf) => shelf.name !== name),
            }
          : rack
      )
    );
  };

  const deleteSensor = (index) => {
    setRacks((prev) =>
      prev.map((rack) =>
        rack.id === rackId
          ? {
              ...rack,
              shelves: rack.shelves.map((shelf) =>
                shelf.name === name
                  ? {
                      ...shelf,
                      sensors: shelf.sensors.filter((_, i) => i !== index),
                    }
                  : shelf
              ),
            }
          : rack
      )
    );
  };
  const totalProductCount = sensors.reduce(
    (productCount, sensor) =>
      productCount + sensor.products.reduce((count, product) => count + 1, 0),
    0
  );

  const totalProductQuantity = sensors.reduce(
    (quantityCount, sensor) =>
      quantityCount +
      sensor.products.reduce(
        (sum, product) => sum + (product.quantity || 0),
        0
      ),
    0
  );



  return (
    <div className="shelf-container mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mb-2">
          {name}
          <span className="text-muted">
            : ( {t ("shelf.sensors")}: {sensors.length}, {t("shelf.product")}: {totalProductCount}, {t("shelf.totalQuantity")}: {totalProductQuantity} )
          </span>
        </h6>

        <ConfirmationAlertCross
          title="Are you sure?"
          text="This will delete the shelf permanently."
          onConfirm={deleteShelf}
        />
      </div>
      <div
        className="sensors-row align-items-center"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        {sensors.map((sensor, index) => (
          <div
            key={index}
            className="sensor-wrapper"
            style={{
              position: "relative",
              display: "inline-block",
              minWidth: "130px",
            }}
          >
            <button
              className="sensor-btn btn btn-outline-primary"
              style={{
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => handleSensorClick(index)}
            >
              <div className="mt-3">
                {sensor.products && sensor.products.length > 0 ? (
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "12px",
                      marginTop: "5px",
                      color: "#555",
                    }}
                  >
                    {sensor.products.map((product, index) => (
                      <div
                        className="d-flex align-items-center"
                        key={product.id}
                        style={{ marginBottom: "5px" }}
                      >
                      <div title={product?.name ? product.name : product.catalog?.name}>
                          <ImageWithBasePath
                            type="link"
                            src={
                              product?.image
                                ? product.image
                                : product?.catalog?.main_product_image
                            }
                            alt="Product"
                            style={{ width: 30, height: 30, marginRight: 5 }}
                          />
                        </div>
                        <span className="">x</span>
                        <span className="fw-bolder">
                          {product.quantity || 0}
                        </span>
                        {index < sensor.products.length - 1 && (
                          <span className="mx-2 fs-6">,</span>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p
                    className="text-muted"
                    style={{
                      fontSize: "12px",
                      marginTop: "21px",
                      marginBottom: "10px",
                      color: "#555",
                    }}
                  >
                    {t("shelf.noProductAdded")}
                  </p>
                )}
                <hr className="mt-0 mb-1" />
                Sensor {sensor.sensor_number}
              </div>
            </button>
            <div
              className="delete-btn-wrapper"
              style={{
                position: "absolute",
                top: "2px",
                right: "0px",
              }}
            >
              <ConfirmationAlertCross
                title="Delete Sensor?"
                text="This will delete the sensor and all associated products."
                onConfirm={() => deleteSensor(index)}
              >
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    padding: "3px 6px",
                    borderRadius: "50%",
                    fontSize: "10px",
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </ConfirmationAlertCross>
            </div>
          </div>
        ))}
        <PlusCircle
          className="me-2"
          size={24}
          color={"#82B632"}
          onClick={addSensor}
        />
      </div>
      {/* <hr/> */}

      {modalIsOpen && (
        <SensorModal
          isOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          sensorIndex={currentSensorIndex}
          rackNumber={rackId}
          shelfNumber={name}
          saveProductDetails={(sensorIndex, selectedProducts) => {
            setRacks((prev) =>
              prev.map((rack) =>
                rack.id === rackId
                  ? {
                      ...rack,
                      shelves: rack.shelves.map((shelf) =>
                        shelf.name === name
                          ? {
                              ...shelf,
                              sensors: shelf.sensors.map((sensor, i) =>
                                i === sensorIndex
                                  ? { ...sensor, products: selectedProducts }
                                  : sensor
                              ),
                            }
                          : shelf
                      ),
                    }
                  : rack
              )
            );
          }}
        />
      )}
    </div>
  );
};

export default Shelf;
