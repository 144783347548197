import React, { useEffect, useState } from "react";
import { Modal, Button, Form, ListGroup, InputGroup } from "react-bootstrap";
import productHttpService from "../services/http/products/product.service";
import shelfHttpService from "../services/http/shelf-management/shelf.service";
import { useParams } from "react-router-dom";
import usePagination from "../custom-hooks/usePagination";
import ImageWithBasePath from "../core/img/imagewithbasebath";
import UnlimitLoading from "../components/ui-kit/unlimit-loading";
import { useTranslation } from "react-i18next";


const SensorModal = ({
  isOpen,
  closeModal,
  sensorIndex,
  rackNumber,
  shelfNumber,
  saveProductDetails,
}) => {
  const [products, setProducts] = useState([]);
  const [allSelectedProducts, setAllSelectedProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const { id: outletId } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState({ nameFilter: "", eanFilter: "" });

  const { paginationParams, updatePaginationParams } = usePagination({
    currentPage: 1,
    productsPerPage: 10,
  });

  const { currentPage, productsPerPage } = paginationParams;

  useEffect(() => {
    if (!isOpen) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const productResponse = await productHttpService.productList({
          page: currentPage,
          per_page: productsPerPage,
          name: searchParams.nameFilter,
          ean: searchParams.eanFilter,
        });
        const productsList = productResponse?.status === 200 ? productResponse.data.data.data : [];
        setTotalProducts(productResponse?.data.data.total || 0);

        const preselectedResponse = await shelfHttpService.getSaveProduct(outletId);
        const preselectedList = preselectedResponse?.status === 200 ? preselectedResponse.data.data : [];
        const preselectedProducts = preselectedList
          .filter((rack) => rack.id.toString() === rackNumber.toString())
          .flatMap((rack) =>
            rack.shelves
              .filter((shelf) => shelf.name === shelfNumber)
              .flatMap((shelf) =>
                shelf.sensors
                  .filter((sensor) => sensor.sensor_number === sensorIndex + 1)
                  .flatMap((sensor) => sensor.products)
              )
          );

        const selected = productsList.filter((product) =>
          preselectedProducts.some((preselected) => preselected.id === product.id)
        );

        const initialQuantities = {};
        preselectedProducts.forEach((product) => {
          initialQuantities[product.id] = product.quantity || 0;
        });

        setProducts(productsList.filter((product) => !selected.some((p) => p.id === product.id)));
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          ...initialQuantities,
        }));

        setAllSelectedProducts((prev) => [
          ...prev,
          ...selected.filter((product) => !prev.some((p) => p.id === product.id)),
        ]);
      } catch (err) {
        setError("Failed to load products. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isOpen, sensorIndex, rackNumber, shelfNumber, outletId, currentPage, productsPerPage, searchParams]);

  useEffect(() => {
    if (!isOpen) {
      setProducts([]);
      setAllSelectedProducts([]);
      setQuantities({});
    }
  }, [isOpen]);

  const toggleProductSelection = (product) => {
    if (allSelectedProducts.some((p) => p.id === product.id)) {
      setAllSelectedProducts((prev) => prev.filter((p) => p.id !== product.id));
      setProducts((prev) => [...prev, product]);
    } else {
      setAllSelectedProducts((prev) => [...prev, product]);
      setProducts((prev) => prev.filter((p) => p.id !== product.id));
    }
  };

  const handleQuantityChange = (productId, quantity) => {
    const validQuantity = Math.max(0, parseInt(quantity) || 0);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: validQuantity,
    }));
  };

  const handleSearch = () => {
    setSearchParams({ nameFilter: paginationParams.nameFilter, eanFilter: paginationParams.eanFilter });
  };

  const handleSave = () => {
    const selectedWithQuantities = allSelectedProducts.map((product) => ({
      ...product,
      quantity: quantities[product.id] || 0,
    }));
    saveProductDetails(sensorIndex, selectedWithQuantities);
    closeModal();
  };

  const handlePageChange = (pageNumber) => {
    updatePaginationParams({ currentPage: pageNumber });
  };

  const handleSearchChange = (e) => {
    updatePaginationParams({ nameFilter: e.target.value });
  };

  const handleEanChange = (e) => {
    updatePaginationParams({ eanFilter: e.target.value });
  };

  const totalPages = Math.ceil(totalProducts / productsPerPage);

  if (!isOpen) return null;


  return (
    <Modal show={isOpen} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Sensor {sensorIndex + 1}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Selected Products Section */}
        <div className="mb-3">
          <div className="fw-bold">{t("shelf.selectedProducts")}</div>
          <ListGroup>
            {allSelectedProducts.map((product) => (
              <ListGroup.Item
                key={product.id}
                className="d-flex justify-content-between align-items-center bg-info text-white"
              >
                <div className="d-flex align-items-center">
                  <Form.Check
                    className="Checkbox"
                    type="checkbox"
                    checked={allSelectedProducts.some((p) => p.id === product.id)}
                    onChange={() => toggleProductSelection(product)}
                  />
                  {product.catalog?.main_product_image ? (
                    <ImageWithBasePath
                      type="link"
                      src={product.catalog?.main_product_image}
                      alt="Product Image"
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                  ) : (
                    <span>{t("shelf.noImage")}</span>
                  )}
                  <div>
                    <div>{product.catalog?.name || "Unnamed Product"}</div>
                    <div>EAN: {product.catalog?.ean || "N/A"}</div>
                  </div>
                </div>
                <InputGroup className="ms-3" style={{ width: "120px" }}>
                  <Form.Control
                    type="number"
                    min="0"
                    value={quantities[product.id] }
                    onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                  />
                </InputGroup>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        {/* Search and Product List Section */}
        <InputGroup className="mb-3">
          <Form.Control
            placeholder={t("shelf.searchByProductName")}
            value={paginationParams.nameFilter}
            onChange={handleSearchChange}
          />
          <Form.Control
            placeholder={t("shelf.searchByProductEAN")}
            value={paginationParams.eanFilter}
            onChange={handleEanChange}
          />
          <Button variant="secondary" onClick={handleSearch}>
            {t("shelf.search")}
          </Button>
        </InputGroup>

        {loading ? (
          <div className="d-flex justify-content-center">
            <UnlimitLoading type="spin" color="#82B632" height={100} width={100} />
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : products.length === 0 ? (
          <p>{t("shelf.noProductsFound")}</p>
        ) : (
          <>
            <div className="d-flex justify-content-between mb-3">
              <div className="fw-bold" style={{ marginLeft: "48px" }}>{t("shelf.products")}</div>
              <div className="fw-bold" style={{ marginRight: "72px" }}>{t("shelf.quantity")}</div>
            </div>

            <ListGroup>
              {products.map((product) => (
                <ListGroup.Item
                  key={product.id}
                  className={`d-flex justify-content-between align-items-center ${
                    allSelectedProducts.some((p) => p.id === product.id) ? "bg-info text-white" : ""
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <Form.Check
                      className="Checkbox"
                      type="checkbox"
                      checked={allSelectedProducts.some((p) => p.id === product.id)}
                      onChange={() => toggleProductSelection(product)}
                    />
                    {product.catalog?.main_product_image ? (
                      <ImageWithBasePath
                        type="link"
                        src={product.catalog?.main_product_image}
                        alt="Product Image"
                        style={{ width: 30, height: 30, marginRight: 10 }}
                      />
                    ) : (
                      <span>{t("shelf.noImage")}</span>
                    )}
                    <div>
                      <div>{product.catalog?.name || "Unnamed Product"}</div>
                      <div>EAN: {product.catalog?.ean || "N/A"}</div>
                    </div>
                  </div>
                  <InputGroup className="ms-3" style={{ width: "120px" }}>
                    <Form.Control
                      type="number"
                      min="0"
                      value={quantities[product.id]}
                      onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                    />
                  </InputGroup>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <div className="d-flex justify-content-center">
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <span className="page-link" style={{ cursor: "pointer" }}>{t("shelf.previous")}</span>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      <span className="page-link" style={{ cursor: "pointer" }}>{index + 1}</span>
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <span className="page-link" style={{ cursor: "pointer" }}>{t("shelf.next")}</span>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>{t("shelf.close")}</Button>
        <Button variant="primary" onClick={handleSave}>{t("shelf.save")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SensorModal;
