import React, { useEffect, useState } from "react";
import outletService from "../../../services/http/stores/outlet.service";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import Select from "react-select";
import { useSelector } from "react-redux";
import OutletDetailsLogs from "../outlets/Details/outlet-details-logs";
const OutletLogsList = () => {
  const { hasPermission, isVendorOrOutlet,isOutletRole } =
    useRoleAndPerm();
  const { t } = useTranslation();
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const vendors = useSelector((state) => state.vendors.vendors);


  useEffect(() => {
    setSelectedOutletId(null);
    if (selectedVendor?.value) {
      setPurchaseIsLoading(true);
      fetchOutlet();
    } else{
      if (vendors?.data?.data[0]?.id) fetchOutlet();
    }
  }, [selectedVendor]);
  async function fetchOutlet(vendor) {
    try {
      const outlet = await outletService.outletList({
        vendor_id: selectedVendor?.value,
      });
      setOutlet(outlet?.data?.data?.data);
      if (outlet?.data?.data?.data && outlet?.data?.data?.data.length > 0) {
        setSelectedOutletId({
          ...outlet?.data?.data?.data[0],
          value: outlet?.data?.data?.data[0].id,
          label: outlet?.data?.data?.data[0].name,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPurchaseIsLoading(false);
    }
  }
  useEffect(() => {
    if (!isVendorOrOutlet) {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      setSelectedVendor({
        ...selectedVendor,
        value: selectedVendor?.id,
        label: selectedVendor?.business_name,
      });
    }
  }, []);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("tableColumnName.outletLogs"), active: true },
  ];


  const outletOptions = outlet?.map((outlet) => ({
    ...outlet,
    value: outlet.id,
    label: outlet.name,
  }));
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("tableColumnName.outletLogs")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {/* /Filter */}
              <div
                className={`card visible mt-md-4 mb-md-3`}
                id="filter_inputs"
                style={{ display: "block" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    {!isVendorOrOutlet && (
                      <div className="col-lg-5 col-sm-5 col-12 ">
                        <div className="unlimit-uikit-select">
                          <label className="mb-2">{t("vendor.vendor")}</label>
                          <Select
                            options={vendorOptions}
                            className="select custom-select"
                            placeholder={t(
                              "inputField.placeholder.chooseVendor"
                            )}
                            value={selectedVendor}
                            formatOptionLabel={({ label, logo_url }) => (
                              <>
                                <img
                                  src={logo_url}
                                  alt=""
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 10,
                                  }}
                                />
                                <span style={{ marginLeft: 10 }}>{label}</span>
                              </>
                            )}
                            onChange={(selectedOption) =>
                              setSelectedVendor(selectedOption)
                            }
                          />
                        </div>
                      </div>
                    )}
                    {!isOutletRole && (
                      <div className="col-lg-5 col-sm-5 col-12 ">
                        <div className="unlimit-uikit-select">
                          <label className="mb-2">{t("outlet.outlet")}</label>
                          <Select
                            options={outletOptions}
                            className="select custom-select"
                            placeholder={t(
                              "inputField.placeholder.chooseOutlet"
                            )}
                            value={selectedOutletId}
                            formatOptionLabel={({ label, image }) => (
                              <div className="d-flex">
                                {image && <img
                                  src={image}
                                  alt=""
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 10,
                                    objectFit: "contain",
                                  }}
                                />}
                                <span style={{ marginLeft: 10 }}>{label}</span>
                              </div>
                            )}
                            onChange={(selectedOption) =>
                              setSelectedOutletId(selectedOption)
                            }
                            isLoading={purchaseIsLoading}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <OutletDetailsLogs type="OUTLET" id={selectedOutletId?.value} />
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default OutletLogsList;
