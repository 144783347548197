// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(292deg, #131416 0%, #202022 49.26%, #121315 99.52%);
  }
  

  
  .expand-button:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
   */

   .expand-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 10;
  }
  .minimize-button {
    position: absolute;
    top: 90px;
    right: 50px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    z-index: 10;
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 10;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/sample/sample-iphone/3dStyles.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;IAqBI;;GAED;IACC,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,2BAA2B;IAC3B,kBAAkB;IAClB,kCAAkC;IAClC,cAAc;EAChB;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* .canvas-container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(292deg, #131416 0%, #202022 49.26%, #121315 99.52%);\n  }\n  \n\n  \n  .expand-button:hover {\n    background-color: rgba(255, 255, 255, 0.9);\n  }\n  \n  .fullscreen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1000;\n  }\n   */\n\n   .expand-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 5px;\n    z-index: 10;\n  }\n  .minimize-button {\n    position: absolute;\n    top: 90px;\n    right: 50px;\n    border: none;\n    cursor: pointer;\n    border-radius: 5px;\n    z-index: 10;\n  }\n  .loader {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    text-align: center;\n    color: white;\n    z-index: 10;\n  }\n  \n  .spinner {\n    width: 50px;\n    height: 50px;\n    border: 4px solid rgba(255, 255, 255, 0.3);\n    border-top: 4px solid white;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    margin: 0 auto;\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
