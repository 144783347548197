import { useState } from "react";
import Shelf from "./Shelf";
import ConfirmationAlert from "./confirm-alert";
import { ChevronUp, ChevronDown } from "feather-icons-react/build/IconComponents";
import { useTranslation } from "react-i18next";


const Rack = ({ id, number, name, shelves, setRacks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const addShelf = () => {
    setRacks((prev) =>
      prev.map((rack) =>
        rack.id === id
          ? {
              ...rack,
              shelves: [
                ...rack.shelves,
                { id: null, name: `Shelf ${rack.shelves.length + 1}`, sensors: [] },
              ],
            }
          : rack
      )
    );
  };

  const deleteRack = () => {
    setRacks((prev) => prev.filter((rack) => rack.id !== id));
  };

  // Calculate the total number of sensors
  const totalSensors = shelves.reduce(
    (sensorCount, shelf) => sensorCount + shelf.sensors.length,
    0
  );

  // Calculate the total product count
  const totalProductCount = shelves.reduce((productCount, shelf) => {
    return (
      productCount +
      shelf.sensors.reduce(
        (sensorProductCount, sensor) =>
          sensorProductCount +
          sensor.products.reduce(
            (sum, product) => sum + (product.quantity || 0),
            0
          ),
        0
      )
    );
  }, 0);

  return (
    <div className="d-flex flex-column align-items-center mb-3 rack-container-full">
      <div className="d-flex align-items-center w-100">
        <div className="py-2 px-3 d-flex justify-content-between align-items-center rack-container w-100">
          <h6>
            {name} :
            <span className="text-muted">
             ( {t("shelf.shalves")}: {shelves.length}, {t ("shelf.sensors")}: {totalSensors}, {t("shelf.product")}: {totalProductCount} )
            </span>
          </h6>
          <div className="d-flex align-items-center">
            <div onClick={toggleDropdown} className="btn-sm me-2">
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
            <ConfirmationAlert
              title="Are you sure?"
              text="This will delete the rack permanently."
              onConfirm={deleteRack}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="rack-details w-100 p-3">
          {shelves.map((shelf, index) => (
            <Shelf
              key={shelf.id || index}
              rackId={id}
              name={shelf.name}
              sensors={shelf.sensors}
              setRacks={setRacks}
            />
          ))}
          <button onClick={addShelf} className="btn btn-primary mt-2">
            {t("shelf.addShelf")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Rack;
