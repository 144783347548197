import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Minimize2 } from "react-feather";
import { Model } from "./3d-window";

export default function ThreedFileWindowIphoneExpanded() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const files = location.state?.files; // Access files from location state
  const navigateBack = location.state?.navigateBack; // Access files from location state

  return (
    <div className="page-wrapper">
      <div
        style={{
          height: "86vh",
          background: "linear-gradient(292deg, #131416 0%, rgb(79 79 79) 49.26%, #121315 99.52%)",
        }}
      >
        <div className="minimize-button" onClick={() =>  navigate(navigateBack.replace(":id",id))}>
          <Minimize2 size={36} />
        </div>
        <Canvas className="canvas" camera={{ position: [0, 0, 300] }}>
          <OrbitControls enableZoom={true} target={[0, 0, 0]}  />
          <ambientLight intensity={0.5} />
          <directionalLight position={[-2, 5, 2]}/>
          <Suspense fallback={null}>
            {files && <Model files={files} />} {/* Render Model with files */}
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
}
