import React, { useEffect, useState } from "react";
// import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
// import Swal from "sweetalert2";
import httpService from "../../../services/http/loyalty/loyalty-sttings.service";
// import ConfirmationAlert from "../../../components/confirm-alert";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment/moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import Select from "react-select";
import LoyaltySettingsDetail from "../../../components/loyalty-settings-detail";
import { useSelector } from "react-redux";
const LoyaltySettingsList = () => {

  const { hasPermission } = useRoleAndPerm();
  const [loyaltySetting, setLoyaltySetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loyaltySettings, setLoyaltySettings] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const { isVendorOrOutlet } = useRoleAndPerm();
  const vendors = useSelector((state) => state.vendors.vendors);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);
  const getLoyaltySettingsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .loyaltySettingsList(data)
      .then((response) => {
        if (response.status == 200) {
          setLoyaltySettings(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getLoyaltySettingsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);



  const onHideModal = async () => {
    console.log("hide modal");
    setLoyaltySetting(null);
    await getLoyaltySettingsList();
  };
  const editThirdParty = (row) => {
    setLoyaltySetting(row);
    const addButton = document.querySelector(".btn-added");
    if (addButton) {
      addButton.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title:t("tableColumnName.loyaltyPointsSettings"),
      dataIndex: "type",
      render: (text, record) =>
        record.type == 'EARN' ? (
          <span className="badge badge-linesuccess">
            <a>EARN</a>
          </span>
        ) : (
          <span className="badge badge-linewarning">
            <a>REDEEM</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.Amount"),
      dataIndex: "amount",
      align:"right",
      render: (text) => {
        return Number(text);
      },
      // sorter: true,
    },
    {
      title:t("tableColumnName.loyaltyPointsSettings"),
      dataIndex: "points",
      align:"center"
      // sorter: true,
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   render: (text, record) => (
    //     <>
    //     {
    //       record?.vendor && <div className="d-flex align-items-center">
    //       <ImageWithBasePath
    //         type="link"
    //         src={record.vendor?.logo_url}
    //         alt="Contact"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //       {record?.vendor?.business_name}
    //     </div>
    //     }
    //     </>
    //   ),
    // },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },

          {
            title: t("tableColumnName.actions"),
            key: "actions",
            hidden:!hasPermission(["loyalty-point-setting-edit"]),
            render: (text, record) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {hasPermission(["loyalty-point-setting-edit"]) && (
                    <a
                      className="me-2 p-2"
                      onClick={() => editThirdParty(record)}
                    >
                      <i className="feather-edit-2 "></i>
                    </a>
                  )}
                </div>
              </div>
            ),
          },
        ]

  // Breadcrumb items
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("loyalty.loyaltySettingsList"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("loyalty.loyaltySettingsList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div
              className="page-btn"
              style={{
                display: hasPermission(["loyalty-point-setting-add"]) ? "block" : "none",
              }}
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-department"
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body py-4">
            {!isVendorOrOutlet && <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-3 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={loyaltySettings}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoyaltySettingsDetail onHide={onHideModal} loyaltySetting={loyaltySetting}/>
    </div>
  );
};

export default LoyaltySettingsList;
