import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/peoples/vendor.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import notificationService from "../../../services/toaster.service";
import Swal from "sweetalert2";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import moment from "moment/moment";
import DocumentModal from "../../../components/view-document";
import AddDocuments from "../../../components/add-documents";
import { Upload } from "feather-icons-react/build/IconComponents";
import ConfirmationAlertDeleteAttachments from "../../../components/confirm-alert-attachment-delete";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

const VendorDetails = () => {
  const { hasPermission } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { id } = useParams();
  const [vendor, setVendor] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const getVendor = async () => {
    try {
      setIsLoading(true);
      await httpService
        .getVendor(id)
        .then(async (response) => {
          if (response && response.status == 200) {
            setVendor(response.data.data);
            setIsActive(response.data.data.is_active == 0 ? true : false);
          }
        })
        .catch(console.log("error"));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getVendor();
  }, [id]);
  const onConfirmDelete = async (id) => {
    await outletHttpService
      .deleteOutlet(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("outlet.outletDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getVendor();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateVendor = async () => {
    const requestData = {
      is_active: isActive ? 1 : 0,
      id: id,
    };
    await httpService
      .updateVendor(requestData, true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(t("vendor.vendorUpdateSuccess"));
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      sorter,
    });
    // await getOutletsList(pagination);
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("vendor.vendor"), link: "/vendor-list" },
    { label: t("vendor.vendorDetails"), active: true },
  ];
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.outletId"),
      dataIndex: "outlet_code",
      sorter: true,
    },
    {
      title: t("inputField.label.outletName"),
      dataIndex: "image",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.image}
            alt="Outlet"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.ownerName"),
      dataIndex: "owner_name",
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() => navigate(`/outlets/${record.id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
            <a
              className="p-2"
              onClick={() => navigate(`/outlets/${record.id}/edit`)}
            >
              <i className="feather-edit-2 text-black"></i>
            </a>
            <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
          </div>
        </div>
      ),
    },
  ];
  const documentsColumns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.documentName"),
      dataIndex: "name",
    },
    {
      title: t("tableColumnName.fileType"),
      dataIndex: "type",
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="p-2" onClick={() => handleViewDocument(record)}>
              <i className="feather-eye"></i>
            </a>
            <ConfirmationAlertDeleteAttachments
              id={record.id}
              get={getVendor}
            />
          </div>
        </div>
      ),
    },
  ];
  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  const onHideModal = async () => {
    getVendor();
    console.log("hide modal");
  };
  const createDocument = async (formData) => {
    const response = await httpService.createVendorAttachment(formData);
    if (response && response.status === 200) {
      notificationService.successMessage(t("document.documentUploadSuccess"));
    }
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("vendor.vendorDetails")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <div>
          <div>
            <div className="pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                vendor && (
                  <>
                    <div className="row">
                      <div className="col-md-4 d-flex flex-column">
                        <div className="border card p-3 mb-3">
                          <div className="w-100   d-flex justify-content-end ">
                            <span
                              className={`badge ${
                                !isActive ? "badge-linesuccess" : "badge-danger"
                              }`}
                            >
                              <a>
                                {!isActive
                                  ? t("general.actions.activate")
                                  : t("general.actions.deactive")}
                              </a>
                            </span>
                          </div>
                          <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                            <ImageWithBasePath
                              src={vendor?.logo}
                              height={120}
                              width={120}
                              type="link"
                            ></ImageWithBasePath>
                            <div className="mt-4 text-center">
                              <div className="text-black text-center fs-6">
                                <strong>{vendor?.business_name}</strong>
                              </div>
                              <div className="text-center">
                                CVR No. {vendor?.cvr_no}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex w-100 mt-4 gap-3">
                            {hasPermission(["vendor-edit"]) && (
                              <UnlimitHyperlink
                                to={`/vendor/${vendor?.id}/edit`}
                                className="btn btn-primary"
                                type="router-link"
                                style={{ flex: 1 }}
                              >
                                {t("general.actions.edit")}
                              </UnlimitHyperlink>
                            )}
                            {hasPermission(["vendor-delete"]) && (
                              <ConfirmationAlertDeactivate
                                onConfirm={() => updateVendor()}
                                isActive={isActive}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="p-3 card">
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.contactName")}
                                details={vendor.contact_person_name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.phone")}
                                details={vendor.phone}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.email")}
                                details={vendor.email}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-md-8">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.address")}
                                details={vendor.address}
                              />
                            </div>
                            <div className="col-md-4">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.NameConstant")}
                                details={vendor.name_constant}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.zipCode")}
                                details={vendor.zip}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.city")}
                                details={vendor.city.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.country")}
                                details={vendor.country.name}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("outlet.outlet")}
                                details={vendor?.outlet?.length}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdBy")}
                                details={vendor?.created_by?.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdAt")}
                                details={moment(vendor.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.quickPayCurrency")}
                                details={vendor?.quick_pay_currency}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t(
                                  "inputField.label.quickPayMerchantId"
                                )}
                                details={vendor?.quick_pay_merchant_id}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t(
                                  "inputField.label.quickPayAgreementId"
                                )}
                                details={vendor?.quick_pay_agreement_id}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t(
                                  "inputField.label.economicAppSecretToken"
                                )}
                                details={vendor?.economic_app_secret_token}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t(
                                  "inputField.label.economicAgreementAccessToken"
                                )}
                                details={
                                  vendor?.economic_agreement_access_token
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-3">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.privacyPolicyUrl")}
                                details={
                                  vendor?.privacy_policy_url ? (
                                    <a
                                      href={vendor.privacy_policy_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none"
                                    >
                                      <i
                                        className="fas fa-link"
                                        title={vendor.privacy_policy_url}
                                        style={{ marginRight: "5px" }}
                                      />
                                    </a>
                                  ) : (
                                    t("general.noData")
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-4">
                              <UnlimitFieldDetails
                                heading={t(
                                  "inputField.label.termsAndConditionsUrl"
                                )}
                                details={
                                  vendor?.terms_and_conditions_url ? (
                                    <a
                                      href={vendor.terms_and_conditions_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none"
                                    >
                                      <i
                                        className="fas fa-link"
                                        title={vendor.terms_and_conditions_url}
                                        style={{ marginRight: "5px" }}
                                      />
                                    </a>
                                  ) : (
                                    t("general.noData")
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.GDPRUrl")}
                                details={
                                  vendor?.gdpr_url ? (
                                    <a
                                      href={vendor.gdpr_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none"
                                    >
                                      <i
                                        className="fas fa-link"
                                        title={vendor.gdpr_url}
                                        style={{ marginRight: "5px" }}
                                      />
                                    </a>
                                  ) : (
                                    t("general.noData")
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-3">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.aboutUsUrl")}
                                details={
                                  vendor?.about_us_url ? (
                                    <a
                                      href={vendor.about_us_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none"
                                    >
                                      <i
                                        className="fas fa-link"
                                        title={vendor.about_us_url}
                                        style={{ marginRight: "5px" }}
                                      />
                                    </a>
                                  ) : (
                                    t("general.noData")
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.description")}
                                details={vendor.description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <h4 className="fw-bold mb-3">{t("outlet.outlet")}</h4>
          <div className="card">
            {pageLoading ? (
              <div className="row d-flex justify-content-center">
                <UnlimitLoading width={50} height={50} />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <UnlimitTable
                    pagination={false}
                    columns={columns}
                    dataSource={vendor?.outlet}
                    rowKey={(record) => record.id}
                    loading={isLoading}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bold">{t("document.documents")}</h4>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add-department"
              className="btn btn-added"
            >
              <Upload className="me-2 iconsize" />
              {t("general.actions.upload")}
            </Link>
          </div>
          <div className="card">
            {pageLoading ? (
              <div className="row d-flex justify-content-center">
                <UnlimitLoading width={50} height={50} />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <UnlimitTable
                    pagination={false}
                    columns={documentsColumns}
                    dataSource={vendor?.attachments}
                    rowKey={(record) => record.id}
                    loading={isLoading}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            )}
          </div>
          <AddDocuments
            onHide={onHideModal}
            recordId={vendor?.id}
            createDocument={createDocument}
          />
          <DocumentModal
            show={showModal}
            onHide={() => setShowModal(false)}
            document={selectedDocument}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
