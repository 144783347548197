import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/campaign.service";
import { useEffect, useState } from "react";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import notificationService from "../../../services/toaster.service";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import { store } from "../../../core/redux/store";
import moment from "moment";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import { useTranslation } from "react-i18next";
import { UTCToLocalTimeDate } from "../../../utils/dateFunctions";
import useRoleAndPerm from "../../../utils/permissionFunction";

export default function CampaignDetails() {
  const { hasPermission } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const navigate = useNavigate();
  const getCampaign = async () => {
    setLoading(true);
    try {
      setPageLoading(true);
      const response = await httpService.getCampaign(id);
      if (response && response.status === 200) {
        setCampaign(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
      }
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCampaign();
    }
  }, [id]);

  const breadcrumbItems = [
    { link: "/", icon: "feather-home" },
    { label: t("campaign.campaignList"), link: "/campaign-list" },
    { label: t("campaign.campaignDetails"), active: true },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  const updateCampaign = async () => {
    const requestData = {
      is_active: isActive ? 1 : 0,
      id: id,
    };
    await httpService
      .updateCampaign(requestData, true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(
            t("campaign.campaignUpdateSuccess")
          );
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.product?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.product?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.product?.catalog?.name}
        </div>
      ),
    },

    // {
    //   title: t("tableColumnName.category"),
    //   dataIndex: "category",
    //   render: (text, record) => record?.catalog?.category?.name,
    // },
    {
      title: t("tableColumnName.sku"),
      dataIndex: "sku",
      sorter: true,
      render:(text,record)=>record?.product?.sku
    },
    // {
    //   title: t("tableColumnName.uomCode1"),
    //   dataIndex: "catalog.uom_code_1.name",
    //   render: (text, record) => record?.catalog.uom_code_1?.name,
    // },
    {
      title: t("tableColumnName.minOrderQty"),
      dataIndex: "min_order_qty",
      sorter: true,
      align: "center",
      render:(text,record)=>record?.product?.min_order_qty
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission([
        "product-view",
        "product-edit",
        "product-delete",
      ]),
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["product-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/product/${record?.product?.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
          </div>
        </div>
      ),
    },
  ];
  const onHideModal = async () => {
    console.log("hide modal");
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("campaign.campaign")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            campaign && (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <div className="border card p-3 mb-3">
                      <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <ImageWithBasePath
                            src={campaign?.vendor?.logo}
                            height={60}
                            width={60}
                            type="link"
                          ></ImageWithBasePath>
                          <span
                            className={`badge ${
                              !isActive ? "badge-linesuccess" : "badge-danger"
                            }`}
                          >
                            <a>
                              {!isActive
                                ? t("general.actions.active")
                                : t("general.actions.deactive")}
                            </a>
                          </span>
                        </div>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                          <ImageWithBasePath
                            src={campaign?.image}
                            height={120}
                            width={120}
                            type="link"
                          ></ImageWithBasePath>
                          <div className="mt-4 text-center">
                            <div className="text-black text-center fs-6">
                              {/* <strong>{campaign?.name}</strong> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex w-100 mt-4 gap-3">
                        {roles[0].permissions.some(
                          (permission) => permission.name === "campaign-edit"
                        ) && (
                          <UnlimitHyperlink
                            to={`/campaign/${campaign?.id}/edit`}
                            className="btn btn-primary"
                            type="router-link"
                            style={{ flex: 1 }}
                          >
                            {t("general.actions.edit")}
                          </UnlimitHyperlink>
                        )}
                        {roles[0].permissions.some(
                          (permission) => permission.name === "campaign-delete"
                        ) && (
                          <ConfirmationAlertDeactivate
                            onConfirm={() => updateCampaign()}
                            isActive={isActive}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="p-3 card">
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("campaign.campaignName")}
                            details={campaign.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("productGroup.productGroup")}
                            details={campaign.product_group.name}
                          />
                        </div>
                        <div className="col-sm"></div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.startDate")}
                            details={moment(
                              UTCToLocalTimeDate(campaign.start_date)
                            ).format("DD MMM YYYY - HH:mm:ss")}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.endDate")}
                            details={moment(
                              UTCToLocalTimeDate(campaign.end_date)
                            ).format("DD MMM YYYY - HH:mm:ss")}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.discountAmountType")}
                            details={campaign.discount_amount_type}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.discountTypeValue")}
                            details={campaign.discount_amount_type_value}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.createdAt")}
                            details={moment(campaign.created_at).format(
                              "DD MMM YYYY - HH:mm:ss"
                            )}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.createdBy")}
                            details={campaign.created_by?.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="fw-bold">{t("product.products")}</h3>
          </div>
          <div className="p-3 border bg-white border-radius-10">
            <UnlimitTable
              dataSource={campaign?.product_group_lists}
              rowKey={(row) => row.id}
              columns={columns}
              loading={loading}
              pagination={paginationParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 1000 }}
            />
          </div>
        </div>
      </div>
      <AddDocuments
        title={t("document.uploadDocuments")}
        icon={<Upload />}
        onHide={() => onHideModal()}
      />
    </>
  );
}
