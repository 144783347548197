import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import httpService from "../../../services/http/products/catalog.service";
import notificationService from "../../../services/toaster.service";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../Router/all_routes";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import ThreedFileWindow from "../../sample/sample-iphone/3d-window";
const ObjectUpload = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [bmpFile, setBmpFile] = useState(null);
  const [mtlFile, setMtlFile] = useState(null);
  const [objFile, setObjFile] = useState(null);
  const [pngFile, setPngFile] = useState(null);

  const { register, handleSubmit, formState, setError } = useForm();
  const { errors } = formState;

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const onSubmitForm = async () => {
    const formData = new FormData();

    // Append the files
    if (bmpFile) formData.append("bitmap_file", bmpFile);
    if (mtlFile) formData.append("material_file", mtlFile);
    if (objFile) formData.append("object_file", objFile);
    if (pngFile) formData.append("png_file", pngFile);

    if (!bmpFile || !mtlFile || (!objFile && !pngFile)) {
      notificationService.errorMessage(t("general.error.allFilesRequired"));
      return;
    }

    try {
      setIsLoading(true);
      if (id) {
        await httpService
          .add3DObject(id, formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("catalog.catalogUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.catalogList);
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const getProduct = () => {
    setPageLoading(true);
    httpService
      .getCatalog(id)
      .then((response) => {
        if (response && response.status === 200) {
          setProduct(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  useEffect(() => {
    getProduct();
  }, []);
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("catalog.catalog"), link: "/catalog-list" },
    { label: t("catalog.3dObjects"), active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("catalog.3dObjects")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row justify-content-between">
                    <div className="col-lg-6 col-12">
                      <div className="mb-2 d-flex justify-content-between">
                        <label>{t("inputField.label.bmpFile")}</label>
                        <UnlimitFileUpload
                          id="bmpFile"
                          register={register}
                          accept=".bmp"
                          error={errors.bmpFile}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          errorMessage={errors.bmpFile?.message}
                          onChange={(e) => handleFileChange(e, setBmpFile)}
                        />
                      </div>
                      <div className="mb-2 d-flex justify-content-between">
                        <label>{t("inputField.label.mtlFile")}</label>
                        <UnlimitFileUpload
                          id="mtlFile"
                          register={register}
                          accept=".mtl"
                          error={errors.mtlFile}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          errorMessage={errors.mtlFile?.message}
                          onChange={(e) => handleFileChange(e, setMtlFile)}
                        />
                      </div>
                      <div className="mb-2 d-flex justify-content-between">
                        <label>{t("inputField.label.objFile")}</label>
                        <UnlimitFileUpload
                          id="objFile"
                          register={register}
                          accept=".obj"
                          error={errors.objFile}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          errorMessage={errors.objFile?.message}
                          onChange={(e) => handleFileChange(e, setObjFile)}
                        />
                      </div>
                      <div className="mb-2 d-flex justify-content-between">
                        <label>{t("inputField.label.pngFile")}</label>
                        <UnlimitFileUpload
                          id="pngFile"
                          register={register}
                          accept=".png"
                          error={errors.pngFile}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          errorMessage={errors.pngFile?.message}
                          onChange={(e) => handleFileChange(e, setPngFile)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="card">
                        {Object.keys(product?.three_d_files || {}).length >=
                          3 && (
                          <ThreedFileWindow
                            height={"250px"}
                            id={id}
                            files={product?.three_d_files}
                            navigateBack={'/catalog/:id/object-upload'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <Link to={all_routes.catalogList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ObjectUpload;
