import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/category.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
// import UnlimitAttachments from "../../../components/ui-kit/unlimit-attachments";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
// import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { hydrateErrors } from "../../../utils/error.utils";
import { useTranslation } from "react-i18next";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";

const CategoryForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [parentCategoryLoading, setParentCategoryLoading] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue, watch, control,setError } =
    useForm();
  const { errors } = formState;
  const { id } = useParams();
  const [parentCategories, setParentCategories] = useState([]);


  const getParentCategories = async () => {
    await httpService
      .categoriesList({ page: 1, per_page: 10000,is_active:1 })
      .then((response) => {
        if (response && response.status == 200) {
          setParentCategories((prevCategories) => response.data.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(()=>{
        setParentCategoryLoading(false)
      });
  };
  const getCategory = async () => {
    await httpService
      .getCategory(id)
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setValue("description", data.description);
          setValue("is_active", data.is_active == 1 ? true : false);
          setParentCategories((prevCategories) => {
            let parentCategory = prevCategories.find(
              (item) => item.id == data.parent_recursive?.id
            );
            if (parentCategory) {
              parentCategory = {
                ...parentCategory,
                label: parentCategory.name,
                value: parentCategory.id,
              };
              setValue("parent_category", parentCategory);
            }
            return prevCategories;
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(()=>{
        setPageLoading(false)
      });
  };
  const fetchPredefinedValues = async () => {
    if (id) {
      setPageLoading(true)
      await getCategory();
    }
    await getParentCategories();
  };
  useEffect(() => {
    fetchPredefinedValues();
    setParentCategoryLoading(true)
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("is_active", data.is_active ? 1 : 0);
      if (data.parent_category) {
        formData.append("parent_id", data.parent_category.id);
      }
    
      if (id) {
        formData.append("id", id);
        await httpService
          .updateCategory(formData)
          .then((response) => {
            if (response.status == 200) {
              notificationService.successMessage(
               t('category.categoryUpdateSuccess')
              );
              setTimeout(() => {
                navigate(all_routes.categorylist);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await httpService
          .createCategory(formData)
          .then((response) => {
            if (response && response.status == 200) {
              notificationService.successMessage(
                t('category.categoryCreateSuccess')
              );
              setTimeout(() => {
                navigate(all_routes.categorylist);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('category.categories'), link: "/category-list" },
    {
      label: id ? t('category.editProductCategory') : t('category.addProductCategory'),
      active: true,
    },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{id ? t('category.editProductCategory') : t('category.addProductCategory')}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
            {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
              <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitSelect
                      label={t('tableColumnName.parentCategory')}
                      id="parent_category"
                      errors={errors}
                      control={control}
                      isLoading={parentCategoryLoading}
                      register={register}
                      options={parentCategories}
                      placeholder={t('inputField.placeholder.chooseParentCategory')}
                      optionLabel="name"
                      optionValue="id"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label={t('category.categoryName')}
                      id="name"
                      type="text"
                      placeholder={t('inputField.placeholder.enterCategoryName')}
                      register={register}
                      rules={{
                        required: {
                          value: true,
                          message: t("inputField.validation.required"),
                        },
                        maxLength: {
                          value: 50,
                          message: t("inputField.validation.maxLength", {
                            value: 50,
                          }),
                        },
                      }}
                      error={errors.name}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                </div>
              
              <div className="col-md-3 d-flex align-items-end">
                <div className="input-blocks mb-3">
                  <UnlimitToggle
                    label={t('inputField.label.status')}
                    id="is_active"
                    register={register}
                  />
                </div>
              </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3">
                  <UnlimitTextarea
                    label={t('inputField.label.description')}
                    id="description"
                    register={register}
                    rules={{
                      required: t('inputField.validation.required'),
                      maxLength: { value: 500, message: t('inputField.validation.maxLength', { value: 500 }) },
                    }}
                    numOfRows={3}
                      error={errors.description}
                      errorMessage={errors.description?.message}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <Link to={all_routes.categorylist} className="me-2">
                  <button type="button" className="btn btn-cancel">
                  {t("general.button.cancel")}
                  </button>
                </Link>
                <UnlimitSubmitButton
                  label={id
                    ? t("general.button.update")
                    : t("general.button.submit")}
                  isLoading={isLoading}
                />
              </div>
            </div>)}
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
    </div>
  );
};

export default CategoryForm;
