import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/peoples/employees.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";

import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import notificationService from "../../../services/toaster.service";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import { store } from "../../../core/redux/store";
import moment from "moment";
import DocumentModal from "../../../components/view-document";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import ConfirmationAlertDeleteAttachments from "../../../components/confirm-alert-attachment-delete";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import OutletDetailsLogs from "../../stores/outlets/Details/outlet-details-logs";
// const route = all_routes;
export default function EmployeesDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { id } = useParams();
  const [employees, setEmployees] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const navigate = useNavigate();
  const getEmployees = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getEmployees(id);
      if (response && response.status === 200) {
        setEmployees(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      notificationService.errorMessage(t("prompts.recordNotExists"));
      // navigate(route.empl)
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getEmployees();
    }
  }, [id]);

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  const updateEmployee = async () => {
    const requestData = {
      is_active: isActive ? 1 : 0,
      id: id
    };
    await httpService
      .updateEmployees(requestData,true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(
            t("employee.employeeUpdateSuccess")
          );
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      });
  };
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.documentName"),
      dataIndex: "name",
    },
    {
      title: t("tableColumnName.fileType"),
      dataIndex: "type",
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="p-2" onClick={() => handleViewDocument(record)}>
              <i className="feather-eye"></i>
            </a>
            <ConfirmationAlertDeleteAttachments
              id={record.id}
              get={getEmployees}
            />
          </div>
        </div>
      ),
    },
  ];
  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  const onHideModal = async () => {
    getEmployees();
    console.log("hide modal");
  };
  const createDocument = async (formData) => {
    const response = await httpService.createEmployeesDocument(formData);
    if (response && response.status === 200) {
      notificationService.successMessage(t("document.documentUploadSuccess"));
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("employee.employee"), link: "/employees-list" },
    { label: t("employee.employeeDetails"), active: true },
  ];
  const items = [
    {
      key: '2',
      label: 'Outlet Logs',
      children: <OutletDetailsLogs type="EMP" id={id}/>,
    },
    {
      key: '1',
      label: t("document.documents"),
      children: <>
      <div className="d-flex justify-content-between align-items-center mb-3">
            <p></p>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add-department"
              className="btn btn-added"
            >
              <Upload className="me-2 iconsize" />
              {t("general.actions.upload")}
            </Link>
          </div>
          <div className="card">
            <div className="table-responsive">
              <UnlimitTable
                pagination={false}
                columns={columns}
                dataSource={employees?.attachments}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
      </>,
    },
  ]
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("employee.employee")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            employees && (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <div className="border card p-3 mb-3">
                      <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                        <span
                          className={`badge align-self-end ${
                            !isActive ? "badge-linesuccess" : "badge-danger"
                          }`}
                        >
                          <a>
                            {!isActive
                              ? t("general.actions.activate")
                              : t("general.actions.deactive")}
                          </a>
                        </span>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                          <ImageWithBasePath
                            src={employees?.image}
                            height={120}
                            width={120}
                            type="link"
                          />
                          <div className="mt-4 text-center">
                            <div className="text-black text-center fs-6">
                              <strong>{employees?.name}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex w-100 mt-4 gap-3">
                        {roles[0].permissions.some(
                          (permission) => permission.name === "employee-edit"
                        ) && (
                          <UnlimitHyperlink
                            to={`/employees/${employees?.id}/edit`}
                            className="btn btn-primary"
                            type="router-link"
                            style={{ flex: 1 }}
                          >
                            {t("general.actions.edit")}
                          </UnlimitHyperlink>
                        )}
                        {roles[0].permissions.some(
                          (permission) => permission.name === "employee-delete"
                        ) && (
                          <ConfirmationAlertDeactivate
                            onConfirm={() => updateEmployee()}
                            isActive={isActive}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="p-3 card">
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.name")}
                            details={employees.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.phone")}
                            details={employees.phone}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.email")}
                            details={employees.email}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.designation")}
                            details={employees.designation_id?.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("outlet.outlet")}
                            details={employees.outlet?.name}
                          />
                        </div>
                        <div className="col-sm"></div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.description")}
                            details={employees.description}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
          <Collapse accordion items={items} defaultActiveKey={['1']}/>
        </div>
        <AddDocuments
          onHide={onHideModal}
          recordId={employees?.id}
          createDocument={createDocument}
        />
        <DocumentModal
          show={showModal}
          onHide={() => setShowModal(false)}
          document={selectedDocument}
        />
      </div>
    </>
  );
}
