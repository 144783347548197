import { useEffect, useState } from "react";
import UnlimitTable from "../../../../components/ui-kit/unlimit-table";
import httpService from "../../../../services/http/inventory/outlet-stock.service";
import usePagination, {
  createInitialPagination,
} from "../../../../custom-hooks/usePagination";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function OutletsDetailsStock({ id }) {
  const [loading, setLoading] = useState(false);
  const [outletStocks, setOutletStocks] = useState([]);
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState("");
  const [eanFilter, setEanFilter] = useState("");
  const initialPagination = createInitialPagination(t);
  const { paginationParams, updatePaginationParams } =
    usePagination(initialPagination);
  const getOutletStockList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      group_by: "ean",
      ...sorter,
    };
    setLoading(true);
    await httpService
      .outletStockList(data, id)
      .then((response) => {
        if (response.status == 200) {
          setOutletStocks(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      product_name: nameFilter,
      ean: eanFilter,
      outlet_id: id,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if (!pagination) {
      pagination = initialPagination;
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getOutletStockList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getOutletStockList();
  }, []);


  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "purchase_reference.id",
      render: (outlet, record) =>
        record.catalog ? record?.catalog?.ean : null,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt={t("tableColumnName.productName")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.quantity"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "purchase_reference.id",
      render: (outlet, record) =>
        record.purchase ? record?.purchase?.order_no : null,
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
  ];

  return (
    <>
    <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.label.EAN")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={eanFilter}
                    onChange={(e) => setEanFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("tableColumnName.productName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-1 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
      <div className="table-responsive">
        <UnlimitTable
          pagination={paginationParams.pagination}
          columns={columns}
          dataSource={outletStocks}
          rowKey={(record) => record.id}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
}
