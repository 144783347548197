import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/product.service";
import catalogHttpService from "../../../services/http/products/catalog.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetailsRow from "../../../components/ui-kit/unlimit-field-details-row";
import usePagination from "../../../custom-hooks/usePagination";
import notificationService from "../../../services/toaster.service";
import UnlimitImageGallery from "../../../components/ui-kit/unlimit-image-gallery";
import ThreedFileWindow from "../../sample/3d-window";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

export default function ProductImageDetails() {
    const {isVendorOrOutlet } = useRoleAndPerm();
    const [pageLoading, setPageLoading] = useState(false);
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const { t } = useTranslation();

    const getProduct = async () => {
        try {
            setPageLoading(true);
            const response = await httpService.getProduct(id);
            if (response && response.status === 200) {
                setProduct(response.data.data);
            }
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getProduct();
        }
    }, [id]);

    const breadcrumbItems = [
        { label: "", link: "/", icon: "feather-home" },
        { label: "Product", link: "/product-list" },
        { label: "Product Details", link: `/product/${id}/details` },
        { label: "Product Images", active: true },
    ];
    const updateImageStatus = async (imageId, status) => {
        try {
            const formData = new FormData();
            formData.append("id", imageId);
            formData.append("is_active", status ? 1 : 0);
            formData.append("catalog_id", id);
            const response = await httpService.updateCatalogAttachment(formData)
            if (response && response.status === 200) {
                setProduct(response.data.data);
            }
        } catch (error) {
            // Handle errors
        } finally {
            setPageLoading(false);
        }
    };
    const updateDefaultImage = async (imageId, status) => {
        try {
            setPageLoading(true);
            const formData = new FormData();
            formData.append("id", imageId);
            formData.append("is_default", status ? 1 : 0);
            formData.append("catalog_id", id);
            const response = await catalogHttpService.updateCatalogAttachment(formData)
            if (response && response.status === 200) {
                getProduct();
            }
        } catch (error) {
            // Handle errors
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex flex-column">
                            <div className="page-title">
                                <h3 className="text-black">Product Details</h3>
                            </div>
                            <UnlimitBreadcrumb items={breadcrumbItems} />
                        </div>
                    </div>
                    {pageLoading ? (
                        <div className="row d-flex justify-content-center">
                            <UnlimitLoading width={50} height={50} />
                        </div>
                    ) : (
                        product?.catalog && (
                            <>
                                <div className="row g-4 mb-4">
                                        {product?.catalog?.product_images.map((images, index) => (
                                        <div key={index} className="col-md-3">
                                            <div className="card" style={{ height: "100%" }}>
                                                <UnlimitImageGallery
                                                    urls={images}
                                                    isDefaultValue={product?.catalog?.main_product_image}
                                                    disabled={isVendorOrOutlet ?  true : false}
                                                    updateImageStatus={updateImageStatus}
                                                    updateDefaultImage={updateDefaultImage}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </>
    );
}
