import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import React, { useState } from "react";

const UnlimitInput = (props) => {
  const { label, id, type, register, rules, error, errorMessage,borderGreen,borderError, ...attr } =
    props;
  
  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Determine the input type
  const inputType = type === "password" ? (showPassword ? "text" : "password") : type;

  return (
    <div className="unlimit-uikit-input">
      {label && <label className="form-label" htmlFor={id}>
        {label}
      </label>}
      <div className="input-group"  style={{border: '1px solid #e9edf6',borderRadius:'0.35rem'}}>
        <input
          className={`form-control border-0 ${borderGreen ? "border-green-input":""} ${error && borderError ? "border-red-input":""}`}
          type={inputType}
          id={id}
          {...register(id, rules)}
          {...attr}
        />
        {type === "password" && (
          <button
            className="btn"
            type="button"
            onClick={togglePasswordVisibility}
          >
              {showPassword ? <EyeOff className="iconsize" /> : <Eye className="iconsize" />}
          </button>
        )}
      </div>
      {error && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default UnlimitInput;
