import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { X, XCircle } from "feather-icons-react/build/IconComponents";

const ConfirmationAlertCross = ({ title, text, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: title || t("general.actions.areyousure"),
      text: text || t("general.prompts.confirmRevert"),
      showCancelButton: true,
      cancelButtonText: t("general.button.cancel"),
      confirmButtonText: t("general.button.confirm"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else {
        onCancel && onCancel();
        MySwal.close();
      }
    });
  };

  return (
    <a
      className="confirm-text p-2"
      onClick={() => {
        showConfirmationAlert();
      }}
    >
      <XCircle size={14} color={"orange"} />
    </a>
  );
};

export default ConfirmationAlertCross;
