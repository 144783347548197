import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vendorsServices from "../../../services/http/peoples/vendor.service";


export const fetchVendors = createAsyncThunk(
  "vendors/fetchVendors",
  async (isAuthenticated) => {
    try {
      if (isAuthenticated) {
        const response = await vendorsServices.vendorsList();
        return response.data;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);


const initialState = {
  vendors: [],
  status: 'idle',
};

export const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vendors = action.payload;
      })
      .addCase(fetchVendors.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default vendorsSlice.reducer;