import { Link} from "react-router-dom";
import httpService from "../../../../services/http/stores/outlet.service";
import { useState } from "react";
import UnlimitTable from "../../../../components/ui-kit/unlimit-table";
import usePagination from "../../../../custom-hooks/usePagination";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../../components/add-documents";
import notificationService from "../../../../services/toaster.service";
import moment from "moment";
import DocumentModal from "../../../../components/view-document";
import ConfirmationAlertDeleteAttachments from "../../../../components/confirm-alert-attachment-delete";

import { useTranslation } from "react-i18next";

export default function OutletsDetailsDocument({ outlets,getOutlets }) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null)

  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });


  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };


  const documentColumns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.documentName"),
      dataIndex: "name",
    },
    {
      title: t("tableColumnName.fileType"),
      dataIndex: "type",
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="p-2" onClick={() => handleViewDocument(record)}>
              <i className="feather-eye"></i>
            </a>
            <ConfirmationAlertDeleteAttachments
              id={record.id}
              get={getOutlets}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  const onHideModal = async () => {
    getOutlets();
  };
  const createDocument = async (formData) => {
    const response = await httpService.createOutletDocument(formData);
    if (response && response.status === 200) {
      notificationService.successMessage(t("document.documentUploadSuccess"));
    }
  };
  return (
    <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bold">{t("document.documents")}</h4>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add-department"
              className="btn btn-added"
            >
              <Upload className="me-2 iconsize" />
              {t("general.actions.upload")}
            </Link>
          </div>
          <div className="card">
            <div className="table-responsive">
              <UnlimitTable
                pagination={false}
                columns={documentColumns}
                dataSource={outlets?.attachments}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
          <AddDocuments
            onHide={onHideModal}
            recordId={outlets?.id}
            createDocument={createDocument}
          />
          <DocumentModal
            show={showModal}
            onHide={() => setShowModal(false)}
            document={selectedDocument}
          />
      {/* Documents  */}
    </>
  );
}
