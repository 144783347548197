import React, { useEffect, useState } from "react";
import httpService from "../../../../services/http/stores/outlet.service";
import usePagination, {
  createInitialPagination,
} from "../../../../custom-hooks/usePagination";
import UnlimitTable from "../../../../components/ui-kit/unlimit-table";
import { useTranslation } from "react-i18next";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";

const OutletCCTVList = ({type,id}) => {
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t);
  const { paginationParams, updatePaginationParams } =
    usePagination(initialPagination);
  const getOutletsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    1;
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .outletcctvList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {};
    if(type == "OUTLET"){
      filters.vendor_id=id
    } 
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if (!pagination) {
      pagination = initialPagination;
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getOutletsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    handleTableChange();
  }, [id]);
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams.pagination?.current - 1) *
          paginationParams.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.outletName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.image}
            alt="Outlet"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.outletId"),
      dataIndex: "outlet_code",
      sorter: true,
    },
    {
      title: t("inputField.label.NVRURL"),
      dataIndex: "nvr_url",
    },
    {
      title: t("inputField.label.userName"),
      dataIndex: "nvr_username",
    },
    {
      title: t("inputField.label.password"),
      dataIndex: "nvr_password",
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      align:"center",
      render: (text, record) => (
        <div className="action-table-data" style={{minWidth:"140px"}}>
          <div className="edit-delete-action ">
            {(record.nvr_url) && (
              <a
                className="p-2"
                href={record.nvr_url}
                target="blank"
                title={`UserName:${record.nvr_username} Password:${record.nvr_password}`}
              >
                <ImageWithBasePath
                  src="assets/img/icons/cctv-camera-icon.png"
                  alt="img"
                  height={"18px"}
                />
              </a>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* /product list */}
          {/* <div className="table-top row">
            <div className="col-lg-2 col-sm-4 col-12 ms-auto">
              <div className="input-blocks mb-0">
                <button
                  className="btn btn-filters col-auto ms-auto px-2"
                  style={{ width: "fit-content" }}
                  onClick={handleTableChange}
                >
                  <i data-feather="search" className="feather-search m-0" />
                </button>
              </div>
            </div>
          </div> */}
          <div className="table-responsive">
            <UnlimitTable
              pagination={paginationParams.pagination}
              columns={columns}
              dataSource={designations}
              rowKey={(record) => record.id}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
    </div>
  );
};

export default OutletCCTVList;
