import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/product-group.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import notificationService from "../../../services/toaster.service";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function ProductGroupDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [purchase, setPurchase] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getPurchase = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getProductGroup(id);
      if (response && response.status === 200) {
        setPurchase(response.data.data);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            total: response.data.data.length,
          },
        });
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchase();
    }
  }, [id]);

  const handleTableChange = async (pagination, filters, sorter) => {
    updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.product?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.product?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.product?.catalog?.name}
        </div>
      ),
    },
    // {
    //   title: t("category.category"),
    //   dataIndex: "catalog.category.name",
    //   render: (text, record) => record?.catalog?.category?.name,
    // },
    {
      title: t("tableColumnName.sku"),
      dataIndex: "sku",
      render: (text, record) => record?.product?.sku,
    },
    // {
    //   title: t("tableColumnName.uomCode1"),
    //   dataIndex: "catalog.uom_code_1.name",
    //   render: (text, record) => record.catalog?.uom_code_1.name,
    // },
    // {
    //   title: t("brand.brand"),
    //   dataIndex: "catalog.brand.name",
    //   render: (text, record) => (
    //     <div className="d-flex align-items-center">
    //       <ImageWithBasePath
    //         type="link"
    //         src={record.catalog?.brand?.image}
    //         alt="Brand"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //       {text}
    //     </div>
    //   ),
    // },
    {
      title: t("tableColumnName.minOrderQty"),
      dataIndex: "min_order_qty",
      render: (text, record) => record?.product?.min_order_qty,
    },
    {
      title: t("inputField.label.vat"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.product?.catalog?.vat ?? "";
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() =>
                navigate(`/product/${record?.product?.id}/details`)
              }
            >
              <i className="feather-eye"></i>
            </a>
          </div>
        </div>
      ),
    },
  ];

  // Paginate data client-side
  const paginate = (data, current, pageSize) => {
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data?.slice(startIndex, endIndex);
  };

  const paginatedData = paginate(
    purchase.product_group_list,
    paginationParams.pagination.current,
    paginationParams.pagination.pageSize
  );

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("productGroup.productGroup"), link: "/product-group-list" },
    { label: t("productGroup.productGroupDetails"), active: true },
  ];

  const onHideModal = async () => {
    console.log("hide modal");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">
                  {t("productGroup.productGroupDetails")}
                </h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            purchase && (
              <>
                <div className="">
                  <div className="p-3 card">
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.name")}
                          details={purchase.name}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.Number")}
                          details={purchase.number}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.productCount")}
                          details={purchase.productCount}
                        />
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.createdAt")}
                          details={moment(purchase.created_at).format(
                            "DD MMM YYYY - HH:mm:ss"
                          )}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.createdBy")}
                          details={purchase.created_by?.name}
                        />
                      </div>
                      <div className="col-sm"></div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={paginatedData}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <AddDocuments onHide={onHideModal} />
      </div>
    </>
  );
}
