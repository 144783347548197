import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import notificationService from "../services/toaster.service";
import systemLanguageHttpService from "../services/http/settings/system-language.service";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || i18n.language
  );
  const systemLanguages = useSelector(
    (state) => state.systemLanguages.SystemLangauge
  );
  useEffect(() => {
    const fetchInitialTranslations = async () => {
      const id = localStorage.getItem("languageId") || 1;
      const shortCode = localStorage.getItem("languageShortCode") || "en";
      await fetchTranslations(id, "CRM", shortCode);
    };
    fetchInitialTranslations();
  }, []);

  const fetchTranslations = async (id, platform, shortCode) => {
    try {
      const response = await systemLanguageHttpService.getSystemLanguageJson(
        id,
        platform
      );
      if (response && response.status === 200) {
        const translationData = response.data.data.translation;
        const parsedTranslations = JSON.parse(translationData);
        // i18n.addResourceBundle(shortCode, "translation", parsedTranslations);
        i18n.changeLanguage(shortCode);
      }
    } catch (error) {
      notificationService.errorMessage(
        "System Language does not have translation!"
      );
      console.error("Error fetching translations:", error);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
  };

  const handleLanguageChange = (language) => {
    changeLanguage(language.short_code);
    fetchTranslations(language.id, "CRM", language.short_code);
    localStorage.setItem("languageId", language.id);
    localStorage.setItem("languageShortCode", language.short_code);
  };

  return (
    <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
      <Link
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        to="#"
        role="button"
      >
        {selectedLanguage ? (
          <p>{selectedLanguage}</p>
        ) : (
          <FeatherIcon icon="globe" />
        )}
      </Link>
      <div className="dropdown-menu dropdown-menu-right">
        {systemLanguages
          ?.filter((lang) => lang.is_active != 0)
          .map((language) => (
            <p
              key={language.id}
              className={`dropdown-item ${
                selectedLanguage === language.short_code ? "active" : ""
              }`}
              onClick={() => handleLanguageChange(language)}
            >
              <ReactCountryFlag
                style={{ width: "24px", height: "16px" }}
                svg
                className="emojiFlag"
                countryCode={language.country_code}
              />{" "}
              {language.name + " (" + language.short_code + ")"}
            </p>
          ))}
      </div>
    </li>
  );
};

export default LanguageSelector;
