import React, { useEffect, useState } from "react";
import purchaseHttpService from "../../../services/http/purchases/purchase.service";
import { Link, useNavigate } from "react-router-dom";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitButton from "../../../components/ui-kit/unlimit-button";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import moment from "moment";
import notificationService from "../../../services/toaster.service";
const MainStockFromPurchaseSearch = ({ setPurchaseItemsList,setLoading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [purchase, setPurchase] = useState([]);
  const [purchaseCode, setPurchaseCode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");

  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
  } = useForm();

  const { errors } = formState;
  const { t } = useTranslation();

  const getPurchaseList = async (id) => {
    setLoading(true);
    await purchaseHttpService
      .getPurchaseItems(id)
      .then((response) => {
        if (response.status === 200) {
          if(response?.data?.data?.length > 0 && !response.data.data[0].price){
            notificationService.warningMessage(
              t("mainStock.firstneedtoaddpriceline")
            );
            return
          }
          setPurchaseItemsList(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function fetchPurchase() {
    try {
      const purchase = await purchaseHttpService.purchaseList();
      setPurchase(purchase?.data?.data?.data);
    } catch (err) {
      console.log(err);
    } finally{
      setPurchaseIsLoading(false)
    }
  }

  useEffect(() => {
    setPurchaseIsLoading(true)
    fetchPurchase();
  }, []);

  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      await getPurchaseList(data.purchase_id?.id);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedPurchaseId = watch("purchase_id");

  useEffect(() => {
    if (selectedPurchaseId) {
      const selectedPurchase = purchase.find(
        (p) => p.id === selectedPurchaseId.id
      );
      if (selectedPurchase) {
        setPurchaseCode(selectedPurchase.purchase_code);
        setSupplierName(selectedPurchase.supplier.name);
        setPurchaseDate(moment(selectedPurchase.purchase_date).format("DD MMM YYYY"));
      } else {
        setSupplierName("");
        setPurchaseDate("");
      }
    }
    setPurchaseItemsList([]);
    }, [selectedPurchaseId, purchase]);
    const purchaseOptions = purchase?.map((purchase) => ({
      ...purchase,
      logo:purchase?.supplier?.image,
      value: purchase.id,
      label: purchase.order_no + " " +"(" +moment(purchase?.purchase_date).format("DD MMM YYYY") + ")",
    }));
  return (
    <div>
      {/* /Search */}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-md-4">
                <UnlimitSelect
                  label={t("tableColumnName.purchaseRefernce")}
                  id="purchase_id"
                  errors={errors}
                  control={control}
                  isLoading={purchaseIsLoading}
                  register={register}
                  options={purchaseOptions}
                  showLogo={true}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.placeholder.selectpurchaseRefernce")}
                />
              </div>
              {purchaseCode && <div className="col-md-2 mt-2">
                <label className="fw-bold">{t("tableColumnName.purchaseID")}</label>
                <div className="mt-2">{purchaseCode}</div>
              </div>}
              {supplierName && <div className="col-md-2 mt-2">
                <label className="fw-bold">{t("supplier.supplier")}</label>
                <div className="mt-2">{supplierName}</div>
              </div>}
              {purchaseDate&& <div className="col-md-2 mt-2">
                <label className="fw-bold">{t("tableColumnName.purchaseDate")}</label>
                <div className="mt-2">{purchaseDate}</div>
              </div>}
            </div>
            <Link to={all_routes.mainStockList}>
              <button type="button" className="btn btn-cancel me-2">
                {t("general.button.cancel")}
              </button>
            </Link>
            <UnlimitButton
              text={t("general.button.search")}
              isLoading={isLoading}
            />
            {/* /Filter */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default MainStockFromPurchaseSearch;
